<template>
<div>
  <div>
      <div class="mobile_view" style="justify-content: center;">
        
        <!--<nav class="navbar navbar-default" style="background-color: #FAFAFA;">
          <div class="container-fluid">
            <div class="navbar-header">
              <h4><img width="40px" height="40px" src=".././assets/icons/WakawR.svg"> <span style="float: none;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: black;">Login</span></h4>
            </div>
          </div>
        </nav>-->
        <div><img width="200px" height="180px" style="margin-left: 5%;margin-top: -8%;" :src="brandlogourl"></div>
        <div><img style="margin-top:-10px" width="170px" height="100px" class="wtvlogo" src=".././assets/icons/wtv-icon.svg"></div>
        
        <div class="container pt-5 boxContainer">
          <h1 class ="headerH3"><span style="font-size:30px"><img width="125px" height="55px" src=".././assets/icons/logintext.png"></span></h1>
        </div>
        
          <div class="container pt-5" style="display: inline-grid;padding-top: 7% !important;">
    <div v-if="!otpSent">
      <div class="row" style="justify-content:center">
        <div class="col-sm-10">
          <button v-if="!isPhoneInputVisible && !phoneSelected" @click="openPhoneInput" style="width: 100%; margin-top: 5%; margin-bottom:0%;background-color: #CFD8DC;font-weight: 500;" class="btn"><i style="font-size:24px;float:left;color: white;" class="fa fa-phone" aria-hidden="true"></i>LOGIN WITH PHONE</button>
          <input class="inputclass form-control" v-model="phoneNumber" placeholder="Enter Phone Number" v-if="isPhoneInputVisible">
        </div>
      </div>
      <div v-if="phoneSelected" class="row" style="justify-content:center">
        <div class="col-sm-10">
          <button  @click="sendOTP" style="width: 100%; margin-top: 5%; background-color: #CFD8DC;font-weight: 500;margin-bottom:0%" class="btn">SEND CODE</button>
        </div>
      </div>
      <div  class="row" style="justify-content:center">
        <div class="col-sm-10">
          <button v-if="!isEmailInputVisible && !emailSelected" @click="openEmailInput" style="margin-top: 5%;width: 100%; background-color: #CFD8DC;font-weight: 500;" class="btn"><i style="font-size:24px;float:left;color: white;" class="fa fa-envelope-o" aria-hidden="true"></i>LOGIN WITH EMAIL</button>
          <input class="inputclass form-control" style="margin-top:9%;margin-bottom:0%" v-model="email" placeholder="Enter Email" v-if="isEmailInputVisible">
        </div>
      </div>
      <div v-if="emailSelected" class="row" style="justify-content:center">
        <div class="col-sm-10">
          <button  @click="sendOTP" style="width: 100%; margin-top: 5%; background-color: #CFD8DC;font-weight: 500;" class="btn">SEND CODE</button>
        </div>
      </div>
    </div>
    <div v-if="otpSent" class="row" style="justify-content:center">
      <div class="col-sm-10">
        <input class="inputclass form-control" v-model="otp" type="password" placeholder="Enter OTP">
      </div>
    </div>
    <div v-if="otpSent" class="row" style="justify-content:center">
      <div class="col-sm-10">
        <button  @click="nextPage()" style="width: 100%; background-color: #CFD8DC;font-weight: 500;" class="btn"><i style="color: white;font-size:24px;float:left;" class="fa fa-envelope-o" aria-hidden="true"></i>VERIFY OTP</button>
      </div>
    </div>
    </div>
    </div>
  </div>
  </div>
</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.use(VueAxios, axios);

export default {
  name: 'Home',
  props: {
  },
  data() {
      return {
        isPhoneInputVisible:false,
        isEmailInputVisible:false,
        phoneNumber:'',
        email:'',
        phoneSelected:false,
        emailSelected:false,
        otpSent:false,
        myloader:'',
        otp:'',
        mode:''

      }
  },
  methods:{
    startLoader() {
			this.myloader = this.$loading.show({ canCancel: false, color:'#34749B' });
		},
		stopLoader() {
			this.myloader.hide();
    },
    sendOTP(){
      if(this.isPhoneInputVisible)
      {
        if(this.phoneNumber == '')
        {
          alert('Mobile Number is mandatory');
          return;
        }
        else if(this.phoneNumber.length!=10)
        {
          alert('Mobile Number is invalid')
          return;
        }
      }
      if(this.isEmailInputVisible)
      {
        if(this.email == '')
        {
          alert('Email is mandatory');
          return;
        }
      }
      console.log(sessionStorage.getItem('apikey'));
      const headers = {
        'apiKey': sessionStorage.getItem('apikey'),
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId')
      }
      if(this.isPhoneInputVisible)
      {
        var phoneNo = "+91" + this.phoneNumber;
        axios.post(`https://api.wakaw.live/v1/otp`,
        {
            phoneNumber:phoneNo,
            type:"sms"
        },{headers: headers})
        .then(response => {
          console.log(response);
          this.mode = "M";
          this.otpSent = true;
          this.isPhoneInputVisible = false;
          this.isEmailInputVisible = false;
          this.emailSelected = false;
          this.phoneSelected = false;
          console.log('hola')
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
      else if(this.isEmailInputVisible)
      {
        var myemail =  this.email;
        axios.post(`https://api.wakaw.live/v1/otp`,
        {
            email:myemail,
            type:"email"
        },{headers: headers})
        .then(response => {
          console.log(response);
          this.otpSent = true;
          this.mode = "E";
          this.isPhoneInputVisible = false;
          this.isEmailInputVisible = false;
          this.emailSelected = false;
          this.phoneSelected = false;
          console.log('holax')
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
    },
    openPhoneInput(){
      this.isPhoneInputVisible = true;
      this.isEmailInputVisible = false;
      this.phoneSelected = true;
      this.emailSelected = false;
    },
    openEmailInput()
    {
      this.isEmailInputVisible = true;
      this.isPhoneInputVisible = false;
      this.emailSelected = true;
      this.phoneSelected = false;
    },
    getBrandID()
    {
      console.log(this.$route.params.brand);
    },
    nextPage()
    {
      const headers = {
        'apiKey': sessionStorage.getItem('apikey'),
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId')
      }
      if(this.mode == "M")
      {
        this.startLoader();
        var phoneNo = "+91" + this.phoneNumber;
        axios.post(`https://api.wakaw.live/v1/authentication/login`,
        {
            phoneNumber:phoneNo,
            otp : ''+this.otp,
            type:"sms",
        },{headers: headers})
        .then(response => {
          console.log(response);
          console.log(response.data.data.accessToken);
          sessionStorage.setItem('accessToken',response.data.data.accessToken);
          sessionStorage.setItem('userId',response.data.data.userId);
          this.stopLoader();
          if(this.$route.params.brand==null || this.$route.params.brand == '')
          {
            console.log('sayonaraa')
            this.$router.push({ path: '/404notfound' });
          }
          else
          {
            if(this.$route.params.videoId != null && this.$route.params.videoId != '')
            {
              if(this.$route.query.uid != null && this.$route.query.uid != '')
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId + '?uid=' + this.$route.query.uid}); 
              }
              else
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId}); 
              }
            }
            else
            {
              this.$router.push({ path: '/Video/'+this.$route.params.brand }); 
            }
          } 
        })
        .catch(e => {
          this.stopLoader();
          console.log('sayonaraa112')
          this.$router.push({ path: '/404notfound' });
          this.errors.push(e)
        })
      }
      else if(this.mode == "E")
      {
        this.startLoader();
        var myemail =  this.email;
        axios.post(`https://api.wakaw.live/v1/authentication/login`,
        {
            email:myemail,
            otp : ''+this.otp,
            type:"email",
        },{headers: headers})
        .then(response => {
          console.log(response);
          console.log(response.data.data.accessToken);
          sessionStorage.setItem('accessToken',response.data.data.accessToken);
          sessionStorage.setItem('userId',response.data.data.userId);
          this.stopLoader();
          if(this.$route.params.brand==null || this.$route.params.brand == '')
          {
            this.$router.push({ path: '/404notfound' });
          }
          else
          {
            if(this.$route.params.videoId != null && this.$route.params.videoId != '')
            {
              if(this.$route.query.uid != null && this.$route.query.uid != '')
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId + '?uid=' + this.$route.query.uid}); 
              }
              else
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId}); 
              }
            }
            else
            {
              this.$router.push({ path: '/Video/'+this.$route.params.brand }); 
            }
          }
        })
        .catch(e => {
          this.stopLoader();
          this.$router.push({ path: '/404notfound' });
          this.errors.push(e)
        })
      }
    },
    getConfig()
    {
      //https.globalAgent.options.rejectUnauthorized = false;
      axios.get(`https://api.wakaw.live/v1/config?appVersionNumber=v1.0.0&appType=customer&appVersionName=android`)
      .then(response => {
        console.log(response.data.data.apiKey);
        sessionStorage.setItem('apikey',response.data.data.apiKey);
        this.upsertDevice(response.data.data.apiKey);
      })
      .catch(e => {
        this.errors.push(e)
      })
    },
    upsertDevice(key)
    {
      console.log(key);
      const headers = {
        'apiKey': key,
      }
      axios.post(`https://api.wakaw.live/v1/devices`,
      {
          type: "phone",
          osName: "android",
          osVersion: "10.1",
          deviceIdentifier: "9876548527818ert454512",
          application: {
              applicationType: "customer",
              applicationName: "WakaW Customer App",
              applicationVersionName: "new version1",
              applicationVersionNumber: "0.2",
              applicationKey : "com.wakaw.customer",
              deviceToken: "9ghsj7894522"
          }
      },{headers: headers})
      .then(response => {
        console.log(response);
        sessionStorage.setItem('deviceId',response.data.data.deviceId);
        sessionStorage.setItem('userId',response.data.data.userId);
        sessionStorage.setItem('apikey',response.data.data.apiKey);
      })
      .catch(e => {
        this.errors.push(e)
      })
    }
  },
  computed:{
    brandlogourl:function()
    {
      var url = window.location.origin + '/blogo/';
      url = url + this.$route.params.brand + '.svg';
      return url;
    },
  },
  created()
  {
    try{
      if(sessionStorage.getItem('accessToken'))
      {
        this.$router.push({ path: '/Video/'+this.$route.params.brand });
      }
      else
      {
        try{
          this.getConfig();
        }
        catch(e)
        {
          console.log(e);
        }
        //this.getBrandID();
      }
    }
    catch(e)
    {
      console.log(e);
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
  window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>
.header {
  text-align: center;
  padding: 32px;
}

.row {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create two equal columns that sits next to each other */
.column {
  -ms-flex: 50%; /* IE 10 */
  flex: 50%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

/* Style the buttons */
.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
      /*font-family: 'Lato', sans-serif;
      font-family: 'Roboto', sans-serif;
      font-family: 'Kalam', cursive;
      font-family: 'Patrick Hand', cursive;*/
      font-family: 'Comic Neue', cursive;
    font-weight: 400;
    word-spacing: 5px;
  border-radius: 4px;
  font-size: 16px;
  color: #212121;
}

.btn:hover {
  background-color: #ddd;
}

.btn.active {
  background-color: #666;
  color: white;
}



.loginHeader {
position: absolute;
left: 12.22%;
right: 12.22%;
top: 18.12%;
bottom: 76.88%;
}

.headerH3
{
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 32px;
display: flex;
    align-items: flex-end
}
/* identical to box height, or 133% */
.boxContainer
{
display: flex;
align-items: center;
text-align: center;
justify-content: center;
}
/* Primary — 900 */
.headerColor
{
color: #212121;
}
.inputclass{
width:100%;
height:45px;
font-family: 'Comic Neue', cursive !important;
margin-bottom:5%
}
input:focus{
  border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.pt-5, .py-5 {
    padding-top: 1vh !important;
}
.input{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
letter-spacing: 0.2px;

/* Primary — 500* */

color: #838383;
mix-blend-mode: normal;
}
::-webkit-scrollbar-thumb {
    background: transparent;
}
input:focus{
  border: none;
}
input:active{
  border: none;
}
.container-fluid{
  padding: 0px;
}
.row{
  margin-left: 0;
  margin-right: 0;
}
.videoSection {
        position: relative;
        overflow: hidden;
    }

    .video{
      width: 100%;
      /*max-height: 700px !important;*/
    }



@media (max-width: 580px) {

  .shoe_box{
    width:35%;
  }

}


@media (max-width: 389px) {
.mobile_view{
  width:100vw !important;
}
}
@media (min-height: 500px) and (max-width: 650px){
.mobile_view{
  width: 100vw !important;
}
.video{
  transform: scale(1.39,1);
  width:94vw;
}
}
@media (min-width: 650px){
.mobile_view{
  width: 390px !important;
  padding-bottom: 25px;
}
}
@media (min-width: 700px){
.mobile_view{
  padding-left: 0px !important;
}
}
.mobile_view{
  width: 389px;
  min-height: 100vh !important;
  padding-top:4px;
  padding-bottom: 9px;
  box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  margin: 0 auto;
      border: 1px solid #020310;
        padding-left: 7px;
    padding-right: 0px;
    padding-top: 3px;
    max-height: 100%;
    height: fit-content;
}
.shoe_box {
    position: relative;
    z-index: 10;
    width: fit-content;
        max-width: 35%;
}
.video_box {
    position: absolute;
    top: 0;
    z-index: 1;
}

</style>
